import React from 'react';
import { Box, Typography, Card, CardContent, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/fontawesome-free-solid';
import { useTranslation } from 'react-i18next';

const QuoteCard = styled(Card)(({ theme }) => ({
  background: '#2f2f2f',
  borderRadius: '30px',
  padding: theme.spacing(3),
  boxShadow: theme.shadows[3],
  zIndex: 10,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(25),
  marginBottom: theme.spacing(15),
}));

const Quote = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <Box sx={{ marginRight: '2rem', marginLeft: '2rem', marginTop: { xs: '-6rem', md: '-4rem' } }}>
      <QuoteCard sx={{ marginBottom: { xs: '3rem', md: '4rem' } }}>
        <CardContent sx={{ marginTop: { xs: '0rem', md: '0rem' } ,marginRight:{xs:'0rem',sm:'4rem'}}}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: isRTL?'El Messiri':  'Raleway',
              fontSize: { xs: '17px', md: '40px' },
              fontWeight: '500',
              textAlign: 'center',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: { xs: '0rem', md: '3rem' }
            }}
            gutterBottom
          >
            <FontAwesomeIcon
              icon={faQuoteLeft}
              style={{ fontSize: {xs:'20px',md:'30px'}, color: '#FFCB74', marginRight: '1rem' ,marginBottom:'8rem',fontFamily: isRTL?'El Messiri':  'lato',}}
            />
            {t("One might say that your ability to evaluate one's own ability is the most important skill of all")}
            <FontAwesomeIcon
              icon={faQuoteRight}
              style={{ fontSize: {xs:'20px',md:'30px'}, color: '#FFCB74', marginLeft: '1rem',marginTop:'8rem' }}
            />
          </Typography>
        </CardContent>
      </QuoteCard>
    </Box>
  );
};

export default Quote;
