import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Button, Typography } from '@mui/material';
import con from '../ASSETS/h17.jpg';
import { useTranslation } from 'react-i18next';
export default function Header() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const handleApplyClick = () => {
    window.open('https://form.typeform.com/to/hrzWZ2Rz?typeform-source=www.impactusbh.com', '_blank', 'noopener,noreferrer');
  };

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'}>
    <Box  sx={{ flexGrow: 1,  padding: { xs: '1rem', md:isRTL?'0rem 3rem 1rem 0rem': '0rem 1rem  0rem 5rem' }, marginTop: { xs: '5rem', md: '11rem', sm: '10rem' } }} id="about-us">
      <Grid container spacing={2} columns={16} alignItems="center">
        <Grid xs={16} md={8} order={{ xs: 2, md: 1 }} sx={{ textAlign:{xs:isRTL?'center':'center',md:isRTL?'center':'left'} }}>
          <Typography
            variant="h3"
            sx={{
              color: '#2F2F2F',
              fontFamily: isRTL?'El Messiri': 'DM Serif Display',
              fontWeight: 800,
              fontSize: { xs: '30px', sm: '50px', md: '60px' },
              marginBottom:{xs:'2rem',md: '4rem'},
              marginTop:{xs:'2rem',md:'0rem',sm:'3rem'},
              direction: isRTL ? 'rtl' : 'ltr' 

            }}
          >
            {t("Welcome to the Leadership Bureau ")}
          </Typography>
          <Typography
            sx={{
              color: '#2F2F2F',
              fontFamily: isRTL?'El Messiri':  'Raleway',
              fontSize: { xs: '16px', md: '20px' },
              marginBottom: {xs:'1rem' ,md:'2rem'},
              fontWeight: '500',
              textAlign: {xs:isRTL?'center':'center',md:isRTL?'center':'left'},
              direction: isRTL ? 'rtl' : 'ltr' 
            }}
          >
           {t("where we believe in the power of small and medium-sized enterprises (SMEs) to change the world.")}
          </Typography>
          <Typography
            sx={{
              color: '#2F2F2F',
              fontFamily: isRTL?'El Messiri':  'Raleway',
              fontSize: { xs: '16px', md: '20px' },
              marginBottom: '0rem',
              fontWeight: '500',
              textAlign:{xs:isRTL?'center':'center',md:isRTL?'center':'left'},
              direction: isRTL ? 'rtl' : 'ltr' 
            }}
          >
           {t("Are you ready to take your business to the next level ?")}
          </Typography>
          <Typography
            sx={{
              color: '#2F2F2F',
              fontFamily: isRTL?'El Messiri':  'Raleway',
              fontSize: { xs: '16px', md: '20px' },
              marginBottom: '0rem',
              fontWeight: '500',
              textAlign: {xs:isRTL?'center':'center',md:isRTL?'center':'left'},
              direction: isRTL ? 'rtl' : 'ltr' 
            }}
          >
           {t("We're here to help.")}
          </Typography>
          <Button
            onClick={handleApplyClick}
            sx={{
              fontFamily: isRTL?'El Messiri':  'Raleway',
              fontSize: { xs: '12px', md: '15px' },
              backgroundColor: '#2F2F2F',
              borderRadius: '20px',
              color: 'white',
              padding: { xs: '0.8rem', md: '1.2rem' },
              fontWeight: 300,
              border: '1px solid #2F2F2F',
              marginTop:{xs:'1rem',md: '4rem'},
              '&:hover': {
                backgroundColor: 'white',
                color: '#2F2F2F',
                border: '1px solid #2F2F2F',
              },
            }}
          >
           {t("Book a consultation")}
          </Button>
        </Grid>
        <Grid xs={16} md={8} order={{ xs: 1, md: 2 }} sx={{ textAlign: 'center' }}>
            <Box sx={{margin:{xs:'-2rem 3rem',md:' 0rem 2rem',sm:'0rem 1rem'}}}>
          <img
            src={con}
            style={{
              height: 'auto',
              width: '93%',
              maxWidth: { xs: '100%', sm: '80%', md: '600px' },
              margin: { /* xs: '1rem 0', sm: '2rem 0', md: '2rem' */ },
              borderRadius:isRTL?'0% 40% 40% 40%': '40% 0% 40% 40%',
            }}
            alt="Digital landscape"
          /></Box>
        </Grid>
      </Grid>
    </Box></div>
  );
}
