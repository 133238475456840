import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Card, CardContent, Grid } from '@mui/material';
import a from '../ASSETS/ENGAGEMENT STAGES CONSULTATION JOURNEY (3).png';
import one from '../ASSETS/purpose.png';
import four from '../ASSETS/goal.png';
import two from '../ASSETS/reporting.png';
import three from '../ASSETS/challenge.png';
import { AllInclusive } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: '1px solid black',
  borderRadius: 25,
  marginBottom: 16,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 8,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 16,
  borderTop: '1px solid rgba(0, 0, 0, 0.125)',
  fontFamily: 'Raleway',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 1500,
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, #f5f7fa, #D3CEC8)', // Gradient background color
  borderRadius: '20px',
  boxShadow: theme.shadows[3],
  flex: '1 1 45%',
  marginBottom: '0rem',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const Image = styled('img')({
  width: '70%',
  height: 'auto',
  borderRadius: 8,
});

const accordionData = [
  {
    id: 'panel1',
    title: '360 Business Assessment',
    content: 'Assessing Business Stage (Foundation-Early-Later Growth-Stability), then conducting 360 Business Assessment on All Business Functions',
  },
  {
    id: 'panel2',
    title: 'SWOT Analysis',
    content: 'Conducting an insightful SWOT analysis to identify key components, competencies, opportunities, areas of development and potential threats to the business.',
  },
  {
    id: 'panel3',
    title: 'Problem Solving',
    content: 'Addressing problems that are most crucial and influential on business and its operation, largest financial impact, and least disruption, analyzing cause, means of prevention and countermeasures.',
  },
  {
    id: 'panel4',
    title: 'Strategy Formulation',
    content: 'Based upon the deeper learning and understanding of the business we co-strategize the movement the business will adopt through utilizing internal strength and gravitating towards defined objective.',
  },
  {
    id: 'panel5',
    title: 'Safeguarding Strategy',
    content: 'Engraving the chosen strategy and measures onto the DNA of the business, to ensure growth, consistency, and culture formation to safeguard against recession.',
  },
  {
    id: 'panel6',
    title: 'Sustainability',
    content: 'Continuous growth, proactive mindset, constant initiative and greater awareness, all key to a resilient business.',
  },
];

export default function Approuch() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: '10rem', marginTop: { xs: '-5rem', md: '0rem', sm: '2rem' } }}>
      <Box sx={{ justifyContent: 'center', alignItems: 'center', margin: 'auto auto', width: { xs: '90%', md: '80%' } }}>
        <Typography sx={{ textAlign: 'center', fontFamily: isRTL?'El Messiri':  'Raleway', marginBottom: '6rem', fontWeight: '900' ,color:'#2f2f2f',marginTop:'12rem',direction:isRTL?'rtl':'ltr'}} variant="h4">
          {t("Consulting For")}
        </Typography>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <IconWrapper>
                  <img src={one} style={{ width: '20%', height: 'auto' }} />
                </IconWrapper>
                <Typography sx={{ fontWeight: '600',color:'#2f2f2f',fontFamily: isRTL?'El Messiri':  'lato', }} variant="h5" align="center">
                  {t("Purpose")}
                </Typography>
                <Typography sx={{ marginTop: '1rem' ,fontFamily: isRTL?'El Messiri':  'lato', }} variant="body2" color="textSecondary" align="center">
                  {t("Clear and meaningful direction for your business.")}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <IconWrapper>
                  <img src={four} style={{ width: '20%', height: 'auto' }} />
                </IconWrapper>
                <Typography sx={{ fontWeight: '600',color:'#2f2f2f'  ,fontFamily: isRTL?'El Messiri':  'lato', }} variant="h5" align="center">
                  {t("Success")}
                </Typography>
                <Typography sx={{ marginTop: '1rem' ,fontFamily: isRTL?'El Messiri':  'lato',}} variant="body2" color="textSecondary" align="center">
                  {t("Achieving goals and thriving in your industry.")}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <IconWrapper>
                  <img src={three} style={{ width: '20%', height: 'auto' }} />
                </IconWrapper>
                <Typography sx={{ fontWeight: '600' ,color:'#2f2f2f',fontFamily: isRTL?'El Messiri':  'lato',  }} variant="h5" align="center">
                  {t("Resilience")}
                </Typography>
                <Typography sx={{ marginTop: '1rem',fontFamily: isRTL?'El Messiri':  'lato', }} variant="body2" color="textSecondary" align="center">
                  {t("Strength to withstand challenges and grow.")}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard>
              <CardContent>
                <IconWrapper >
                 
                 <AllInclusive sx={{fontSize:'50px'}}/>
                </IconWrapper>
                <Typography sx={{ fontWeight: '600',fontFamily: isRTL?'El Messiri':  'lato' , fontSize: { xs: '15px', md: '22px' } ,color:'#2f2f2f' }} variant="h5" align="center">
                  {t("Sustainability By Accredited & Internationally Acclaimed Methodologies")}
                </Typography>
                <Typography sx={{ marginTop: '1rem' ,fontFamily: isRTL?'El Messiri':  'lato', }} variant="body2" color="textSecondary" align="center">
                  {t("Implementing best practices for long-term success.")}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>
      <Typography sx={{ textAlign: 'center', fontFamily: isRTL?'El Messiri':  'Raleway', marginBottom: '6rem', fontWeight: '900', marginTop: '8rem' ,color:'#2f2f2f' }} variant="h4">
        {t("Our Approach")}
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
          <Box sx={{ marginLeft: { xs: '1rem', md: '10rem' }, marginRight: { xs: '1rem', md: '0rem' } ,direction:isRTL?'rtl':'ltr'}}>
            {accordionData.map((panel, index) => (
              <Accordion
                key={panel.id}
                expanded={expanded === panel.id}
                onChange={handleChange(panel.id)}
              >
                <AccordionSummary aria-controls={`${panel.id}-content`} id={`${panel.id}-header`}>
                  <Typography sx={{ fontFamily: isRTL?'El Messiri':  'lato', fontWeight: 'bold',direction:isRTL?'rtl':'ltr' }}>{`${index + 1}. ${t(panel.title)}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontFamily: isRTL?'El Messiri':  'lato' ,direction:isRTL?'rtl':'ltr'}}>{t(panel.content)}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
          <Box sx={{ marginLeft: { md: '6rem', sm: '10rem',xs:'6rem' } }}>
            <Image src={a} alt="Approach" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
