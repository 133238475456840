import React from 'react';
import { Card, CardContent, Typography, Box, Avatar, Grid, Paper, Container, Divider } from '@mui/material';
import { Whatshot, Build, Favorite, ThumbUp, People, TrendingUp, Assistant, Looks3, LooksTwo, LooksOne } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const cardData = [
  {
    title: "Understanding",
    content: "Collecting and analyzing information through assessments and behavioral interviews.",
    icon: <LooksOne  sx={{fontSize:'50px'}} />,
    mi:'-2rem',
    bg: '#D3CEC8',
    font : '#2F2F2F', 
     bgi:'transparent',
      color:'black'
     // Orange
  },
  {
    title: "Codifying",
    content: "Identifying enabling and withholding behaviors and their impact on leadership and stakeholders.",
    icon: <LooksTwo sx={{fontSize:'50px'}} />,
    mi:'-2rem',
    bg: '#F6F6F6', 
    font : '#2F2F2F', 
    bgi:'transparent',
    color:'black',// Green
    
  },
  {
    title: "Communication",
    content: "Designing and implementing actions with concrete, specific, measurable, achievable, and time-oriented behaviors.",
    icon: <Looks3  sx={{fontSize:'50px'}}/>,
    mi:'-2rem',
    bg: '#2F2F2F',
    font : 'white',
    bgi:'transparent',
    color:'white' // Blue
  }
];

const CouchFramework = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <>
      <Typography sx={{fontFamily: isRTL?'El Messiri':  'Raleway',marginTop:{xs:'6rem',md:'12rem'},fontWeight:'bold',color:'#2f2f2f' }} variant="h4" gutterBottom align="center">
      {t("The Transformative Power of Coaching")}
      </Typography>
      <Typography sx={{fontFamily: isRTL?'El Messiri':  'Raleway',marginTop:'3rem',fontWeight:'bold',marginRight:{xs:'1rem',md:'5rem'},marginLeft:{xs:'1rem',md:'5rem'},color:'#2f2f2f' }} variant="h6" gutterBottom align="center">
      {t("Coaching helps you gain a deeper understanding of yourself and your relationships. It uncovers hidden sparks behind your blind spots and outdated labels, leading to new discoveries beyond your current knowledge")}
      </Typography>
      <Typography sx={{fontFamily: isRTL?'El Messiri':  'Raleway',fontWeight:'bold',color:'#2f2f2f',margin:'7rem 2rem' }} variant="h4" gutterBottom align="center">
      {t("Our Coaching Framework")}
      </Typography>
      <Box
      
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
        mt: 10,
        marginLeft:{xs:'1rem',md:'0rem'},
        marginRight:{xs:'1rem',md:'0rem'},
        direction:isRTL?'rtl':'ltr'
      }}
    >
        
      
      {cardData.map((item, index) => (
        <Card key={index} sx={{ maxWidth: 400, width: '100%', boxShadow: 3,background:item.bg,}}>
          <CardContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
             
              color: item.font,
            
            }}
          >
            <Avatar sx={{ bgcolor: item.bgi
                , color: item.color ,marginTop:item.mi,fontSize:item.size}}>{item.icon}</Avatar>
            <Box>
              <Typography sx={{fontWeight:'bold',fontFamily: isRTL?'El Messiri':  'lato',marginTop:'1rem'}} variant="h6" gutterBottom>
                {t(item.title)}
              </Typography>
              <Typography sx={{fontFamily: isRTL?'El Messiri':  'lato',marginBottom:'1rem',fontWeight:'500'}} variant="body1">
                {t(item.content)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
   
    </Box>
    </>
  );
};

export default CouchFramework;
