import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, List, ListItem, IconButton, Collapse, useMediaQuery, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 500,
  margin: '1rem', // Margin between cards
  padding: theme.spacing(2),
  backgroundColor: '#DFDAD2',
  borderRadius: '15px',
  boxShadow: theme.shadows[3],
  flex: '1 1 45%',
  marginBottom: { xs: '0rem', md: '6rem' },
}));

const Title = styled(Typography)(({ theme, isRTL }) => ({
  fontFamily: isRTL ? 'El Messiri' : 'Raleway',
  fontWeight: 'bold',
  fontSize: { xs: '20px', md: '2rem' },
  color: '#2F2F2F',
  marginBottom: theme.spacing(2),
  textAlign: 'center', // Center align the title
}));

const ListItemStyled = styled(ListItem)(({ theme, isRTL }) => ({
  fontFamily: isRTL ? 'El Messiri' : 'Raleway',
  fontSize: '1.2rem',
  color: '#2F2F2F',
  textAlign: isRTL ? 'right' : 'left',
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  alignSelf: 'center',
}));

export default function InsightsAndValues() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [expandedCard, setExpandedCard] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect small screens

  const handleExpandClick = (card) => {
    setExpandedCard(expandedCard === card ? null : card);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center', // Centers the cards
        marginLeft: { xs: '1rem', md: '3rem' },
        marginRight: { xs: '1rem', md: '3rem' },
        direction: isRTL ? 'rtl' : 'ltr', // Adjust direction based on language
      }}
    >
      {/* First Card: Values and Insights */}
      <StyledCard>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', direction: isRTL ? 'rtl' : 'ltr' }}>
            <Title sx={{ fontSize: { xs: '22px', md: '30px' }, marginTop: '3rem' }} isRTL={isRTL} variant="h4">
              {t("Courage, Humility and Discipline")}
            </Title>
            {isSmallScreen ? (
              <>
                <ExpandButton
                  onClick={() => handleExpandClick('values')}
                  aria-expanded={expandedCard === 'values'}
                  aria-label="show more"
                >
                  {expandedCard === 'values' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ExpandButton>
                <Collapse in={expandedCard === 'values'} timeout="auto" unmountOnExit>
                  <List>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("These values form the foundation of a strong and ethical business leadership.")}
                    </ListItemStyled>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("Authenticity in your work ensures trust and reliability.")}
                    </ListItemStyled>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("Having a keen understanding of your business operations and limitations.")}
                    </ListItemStyled>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("Using this understanding to support and grow your business, employees, and clients.")}
                    </ListItemStyled>
                  </List>
                </Collapse>
              </>
            ) : (
              <List>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("These values form the foundation of a strong and ethical business leadership.")}
                </ListItemStyled>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("Authenticity in your work ensures trust and reliability.")}
                </ListItemStyled>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("Having a keen understanding of your business operations and limitations.")}
                </ListItemStyled>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("Using this understanding to support and grow your business, employees, and clients.")}
                </ListItemStyled>
              </List>
            )}
          </Box>
        </CardContent>
      </StyledCard>

      {/* Second Card: The Going Concern of Business Owners */}
      <StyledCard>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', direction: isRTL ? 'rtl' : 'ltr' }}>
            <Title sx={{ fontSize: { xs: '22px', md: '30px' }, marginTop: '3rem' }} isRTL={isRTL} variant="h4">
              {t("The Going Concern of Business Owners")}
            </Title>
            {isSmallScreen ? (
              <>
                <ExpandButton
                  onClick={() => handleExpandClick('concern')}
                  aria-expanded={expandedCard === 'concern'}
                  aria-label="show more"
                >
                  {expandedCard === 'concern' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ExpandButton>
                <Collapse in={expandedCard === 'concern'} timeout="auto" unmountOnExit>
                  <List>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("Manage Cash: Inventory, Collection, Purchases")}
                    </ListItemStyled>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("Plan: Financial, Marketing, Sales, Operation")}
                    </ListItemStyled>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("Retain Employees: Hire Right, Job Roles, P&P")}
                    </ListItemStyled>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("Listen: Customers, Employees")}
                    </ListItemStyled>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("Know the Competition: Direct, Indirect")}
                    </ListItemStyled>
                    <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                      • {t("Understand Data: Tracking Data, Performance")}
                    </ListItemStyled>
                  </List>
                </Collapse>
              </>
            ) : (
              <List>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("Manage Cash: Inventory, Collection, Purchases")}
                </ListItemStyled>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("Plan: Financial, Marketing, Sales, Operation")}
                </ListItemStyled>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("Retain Employees: Hire Right, Job Roles, P&P")}
                </ListItemStyled>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("Listen: Customers, Employees")}
                </ListItemStyled>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("Know the Competition: Direct, Indirect")}
                </ListItemStyled>
                <ListItemStyled sx={{ fontSize: { xs: '17px', md: '20px' } }} isRTL={isRTL}>
                  • {t("Understand Data: Tracking Data, Performance")}
                </ListItemStyled>
              </List>
            )}
          </Box>
        </CardContent>
      </StyledCard>
    </Box>
  );
}
