import React from 'react';
import { Box, Typography, Container, Grid, Paper } from '@mui/material';
import { AccessTime, AccessAlarm } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
// Styled Paper for better visual representation
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const HighlightedText = styled(Typography)(({ theme }) => ({
  backgroundColor: '#FFCB74', 
  fontFamily:'Raleway',
  fontWeight:'bold',// Yellow background color
  padding: theme.spacing(1),   // Padding for better visibility
  borderRadius: theme.shape.borderRadius, // Rounded corners
}));

const Interventions = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const data = [
    {
      icon: <AccessTime sx={{ fontSize: 40, color: '#2F2F2F' }} />,
      title: "Short Term Collaboration",
      description: "1 to 3 Months",
    },
    {
      icon: <AccessAlarm sx={{ fontSize: 40, color: '#2F2F2F' }} />,
      title: "Long Term Collaboration",
      description: "3 to 9 months",
    },
  ];

  return (
    <Container sx={{ my: 4, marginTop: '10rem',direction :isRTL ? 'rtl' : 'ltr' }}>
      <Typography sx={{ fontFamily: isRTL?'El Messiri':  'Raleway', marginBottom: '3rem', fontWeight: 'bold' }} variant="h4" gutterBottom align="center">
        {t("Our Interventions")}
      </Typography>
      <Typography sx={{ fontFamily: isRTL?'El Messiri':  'lato', marginBottom: '3rem' }} variant="h6" gutterBottom align="center">
        {t("Intervention will result in some operation disruption as a result of introducing change, that is why we make sure to choose measures with the greatest impact and time it so well that it has the least disruption to operation.")}
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {data.map((item, index) => (
          <Grid sx={{ textAlign: 'center' }} item xs={12} sm={6} md={4} key={index}>
            <Box sx={{ mb: 2 }}>
              {item.icon}
            </Box>
            <Typography variant="h6" sx={{ mb: 1, fontFamily: isRTL?'El Messiri':  'lato', }}>
              {t(item.title)}
            </Typography>
            <HighlightedText variant="body1">
              {t(item.description)}
            </HighlightedText>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Interventions;
