import React from 'react';
import { Card, CardContent, Typography, Box, Avatar, Grid, Paper, Container, Divider } from '@mui/material';
import { Whatshot, Build, Favorite, ThumbUp, People, TrendingUp, Assistant, Looks3, LooksTwo, LooksOne, Looks4, Looks5, Looks6, Filter1, Filter7, Filter6, Filter5, Filter3, Filter4, Filter2 } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const cardData = [
  {
    title: "",
    content: "Determining the Exact Needs & Desired Outcome.",
    icon: <Filter1  sx={{fontSize:'50px'}} />,
    mi:'1rem',
    bg: '#D3CEC8',
    font : '#2F2F2F', 
     bgi:'transparent',
      color:'black'
     // Orange
  },
  {
    title: "",
    content: "Identify clients Values, Preferred Learning Style, Motivation Strategy & Communication Pattern.",
    icon: <Filter2 sx={{fontSize:'50px'}} />,
    mi:'0rem',
    bg: '#F6F6F6', 
    font : '#2F2F2F', 
    bgi:'transparent',
    color:'black',// Green
    
  },
  {
    title: "",
    content: "Expectations Defining, Manifesting & Contracting ROI.",
    icon: <Filter3  sx={{fontSize:'50px'}}/>,
    mi:'1rem',
    bg: '#2F2F2F',
    font : 'white',
    bgi:'transparent',
    color:'white' // Blue
  },
  {
    title: "",
    content: "Personality | We analyze both Yours &Match that with a Suitable Mentor.",
    icon: <Filter4  sx={{fontSize:'50px'}}/>,
    mi:'1rem',
    bg: '#2F2F2F',
    font : 'white',
    bgi:'transparent',
    color:'white' // Blue
  },
  {
    title: "",
    content: "Create Role Openings and Share Detailed Information.",
    icon: <Filter5  sx={{fontSize:'50px'}}/>,
    mi:'1rem',
    bg: '#2F2F2F',
    font : 'white',
    bgi:'transparent',
    color:'white' // Blue
  },
  {
    title: "",
    content: "Challenge vs. Comfort Balance for Optimal Growth.",
    icon: <Filter6  sx={{fontSize:'50px'}}/>,
    mi:'1rem',
    bg: '#2F2F2F',
    font : 'white',
    bgi:'transparent',
    color:'white' // Blue
  },
  {
    title: "",
    content: "Mentor Selection by Client following Profiling & Matching Possibility of Changing Mentor.",
    icon: <Filter7  sx={{fontSize:'50px'}}/>,
    mi:'0rem',
    bg: '#2F2F2F',
    font : 'white',
    bgi:'transparent',
    color:'white' // Blue
  }
];

const MentoringStages = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <>
      <Typography sx={{fontFamily: isRTL?'El Messiri':  'Raleway',marginTop:{xs:'4rem',md:'5rem'},fontWeight:'bold',color:'#2f2f2f' }} variant="h4" gutterBottom align="center">
      {t("Mentoring Matching & Engagment Process")}
      </Typography>
     
      <Box
      
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
        mt: 10,
        marginLeft:{xs:'1rem',md:'0rem'},
        marginRight:{xs:'1rem',md:'0rem'},
        direction:isRTL?'rtl':'ltr'
      }}
    >
        
      
      {cardData.map((item, index) => (
        <Card key={index} sx={{ maxWidth: 400, width: '100%', boxShadow: 3,background:'linear-gradient(135deg, #E5E2DF, #E5E2DF)'}}>
          <CardContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
             marginLeft:'1rem',
              color: '#2f2f2f',
             
              marginTop:item.mi
            
            }}
          >
            <Box sx={{ bgcolor: item.bgi
                , color: '#2f2f2f' ,fontSize:item.size}}>{item.icon}</Box>
            <Box>
              <Typography sx={{fontWeight:'bold',fontFamily: isRTL?'El Messiri':  'lato',marginTop:'1rem'}} variant="h6" gutterBottom>
                {t(item.title)}
              </Typography>
              <Typography sx={{fontFamily: isRTL?'El Messiri':  'lato',marginBottom:'1rem', fontWeight:'700'}} variant="body1">
                {t(item.content)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
   
    </Box>
    </>
  );
};

export default MentoringStages;
