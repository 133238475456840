import Approuch from "../ENGAGEMENT/Approuch";
import Engagement from "../ENGAGEMENT/Engagement";
import Footer from "../FOOTER/Footer";
import Navbar from "../NAVBAR/Navbar";

function ConsultingPage() {
 
    return (
      <div className="App">
        <Navbar></Navbar>
        
        <Approuch></Approuch>
        <Engagement></Engagement>
       
        <Footer></Footer>
       
  
      </div>
    );
  }
  
  export default ConsultingPage;
  