import CouchFramework from "../../COUCHPROGRAM/CouchFramework";
import Footer from "../FOOTER/Footer";
import MentoringStages from "../MENTORING/MentoringStages";
import MentoringDef from "../MENTORING/MentoringDef";

import Navbar from "../NAVBAR/Navbar";

function MentoringPage() {
 
    return (
      <div className="App">
        <Navbar/>
      <MentoringDef></MentoringDef>
       <MentoringStages/>
  <Footer></Footer>
      </div>
    );
  }
  
  export default  MentoringPage 
    ;
  