import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { People, ThumbUp, TrendingUp } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'radial-gradient(circle at top, #E6A817 , #E6A817)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'radial-gradient(circle at top, #E6A817 , #E6A817)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'radial-gradient(circle at top,#E6A817 , #E6A817)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'radial-gradient(circle at top,#E6A817 , #E6A817)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <TrendingUp sx={{  }} />,
    2: <People sx={{ }} />,
    3: <ThumbUp sx={{  }} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
    {
      icon: <TrendingUp sx={{ fontSize: 40, color: '#ff5722' }} />,
      title: "Awareness as the Greatest Change Agent",
      description: "Awareness is crucial for business efficiency, and we help you master key components to improve your operations.",
    },
    {
      icon: <People sx={{ fontSize: 40, color: '#4caf50' }} />,
      title: "Collaborative Partnership",
      description: "We work together to enhance business mindfulness, identify improvement areas, and address critical issues, aiding you in effective prioritization.",
    },
    {
      icon: <ThumbUp sx={{ fontSize: 40, color: '#2196f3' }} />,
      title: "Results-Oriented",
      description: "We focus on delivering results, with clients reporting greater success and happiness after our interventions.",
    },
  ];

export default function Offers() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <Box sx={{marginTop:'10rem',}}>
        <Typography sx={{fontFamily: isRTL?'El Messiri':  'Raleway',marginBottom:'3rem',fontWeight:'bold'}} variant="h4" gutterBottom align="center">
        {t("What We Offer")}
      </Typography>
      <Typography sx={{fontFamily: isRTL?'El Messiri':  'lato',marginBottom:'3rem',direction :isRTL ? 'rtl' : 'ltr'}} variant="h6" gutterBottom align="center">
      {t("Business Owners And Entrepreneurs Work Is Critical To The People, The Community And The Economy.")}
      </Typography>
    <Stack sx={{ width: '100%' }} spacing={4}>
     
      <Stepper alternativeLabel activeStep={2} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label.title}>
            <StepLabel  StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{fontSize:{xs:'10px',md:'20px'},fontWeight:'bold',fontFamily: isRTL?'El Messiri':  'lato',}}> {t(label.title)}</Typography>
 
            <Typography sx={{fontSize:{xs:'10px',md:'15px'},fontWeight:'400',fontFamily: isRTL?'El Messiri':  'lato',direction :isRTL ? 'rtl' : 'ltr'}}> {t(label.description)}</Typography>
                </StepLabel>
           
          </Step>
          
        ))}
      </Stepper>
    </Stack>
    </Box>
  );
}
