import './App.css';
import About from './COMPONENTS/ABOUT/About';
import Offers from './COMPONENTS/OFFERS/Offers';
import Interventions from './COMPONENTS/INTERVENTIONS/Interventions';
import ConsandCouch from './COMPONENTS/CONSANDCOUCH/ConsandCouch';
import Navbar from './COMPONENTS/NAVBAR/Navbar';
import Header from './COMPONENTS/HEADER/Header';
import Method from './COMPONENTS/METHOD/Method';
import Consulting from './COMPONENTS/CONSULTING/Consulting';
import Couching from './COMPONENTS/COUCHINHG/Couching';
import Quote from './COMPONENTS/QUOTE/Quote';
import Footer from './COMPONENTS/FOOTER/Footer';
import Engagement from './COMPONENTS/ENGAGEMENT/Engagement';
import Approuch from './COMPONENTS/ENGAGEMENT/Approuch';
import ConsultingPage from './COMPONENTS/PAGES/ConsultingPage';
import CouchingPage from './COMPONENTS/PAGES/CouchingPage';
import MentoringPage from './COMPONENTS/PAGES/MentoringPage';
import ContactPage from './COMPONENTS/PAGES/ContactPage'; // Correctly imported
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import i18n from './i18n';

function App() {
  const isRTL = i18n.language === 'ar';
  return (
   
    <div /* dir={isRTL ? 'rtl' : 'ltr'} */ className="App">
      <BrowserRouter>
         {/* Navbar inside BrowserRouter */}
        <Routes>
          <Route path="/" element={<><Navbar /><Header /><About /><Offers /><Method /><Interventions /><Consulting /> <Couching /><Quote /><ConsandCouch /><Footer /></>} />
          <Route path="/consulting" element={<ConsultingPage />} />
          <Route path="/couching" element={<CouchingPage />} />
          <Route path="/mentoring" element={<MentoringPage />} />
          <Route path="/contact" element={<ContactPage />} /> {/* Correct path */}
        </Routes>
      </BrowserRouter>
    
    
    </div>
  );
}

export default App;
