import React from 'react';
import { Card, CardContent, Typography, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import m1 from '../ASSETS/m1.jpg'; // Ensure the image path is correct
import m2 from '../ASSETS/m2.jpg'; 
import m3 from '../ASSETS/m3.jpg'; 
import { useTranslation } from 'react-i18next';
const CardContentStyled = styled(CardContent)(({ theme }) => ({
  position: 'relative',
  transition: 'opacity 0.3s ease',
}));

const AnswerBox = styled(Box)(({ theme }) => ({
  opacity: 0,
  transition: 'opacity 0.3s ease',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const CardWrapper = styled(Card)(({ theme, img ,bg}) => ({
  position: 'relative',
  overflow: 'hidden',
  transition: 'background-image 0.3s ease',
  borderRadius: '25px',
  backgroundColor: `url(${img})`,
  '&:hover': {
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    cursor: 'pointer',
    '& .content': {
      opacity: 0,
    },
    '& .answer': {
      opacity: 1,
    },
  },
}));

const Method = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const data = [
    {
      title: "Professional Coaching",
      question: "When do you need it?",
      answer: "Seeking Clarity, Mindfulness, Inspiration & Planning Personally or for Your Organization",
      img: m1,
      bg:'#C6C2BD',
    },
    {
      title: "Accredited Consultation",
      question: "When do you need it?",
      answer: "Focus & Knowledge Challenges on Core Business Functions, and the lack of Time and Efforts to allocate. Noticed a Crack, an Alarm or Raised Flag in how the Business Functions.",
      img: m2,
      bg:'#D3CEC8',
    },
    {
      title: "Certified Mentoring",
      question: "When do you need it?",
      answer: "Being mindful of Knowledge and Experience Gaps and willing to close the same with Reliable and Fitting Experts.",
      img: m3,
      bg:'#DFDAD2',
    },
  ];

  return (
    <Container sx={{ my: 4, marginTop: '8rem', justifyContent: 'center', alignItems: 'center', textAlign: 'center',direction :isRTL ? 'rtl' : 'ltr' }}>
      <Typography sx={{ fontFamily: isRTL?'El Messiri':  'Raleway', marginBottom: '5rem', fontWeight: 'bold' }} variant="h4" gutterBottom align="center">
        {t("Our Methodologies")}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
        {data.map((item, index) => (
          <CardWrapper key={index} sx={{ position: 'relative', width: 350, height: 400 ,backgroundColor:item.bg}} img={item.img}>
            <CardContentStyled className="content">
              <Typography sx={{fontFamily: isRTL?'El Messiri':  'lato', fontWeight: '800', marginTop: '10rem' }} variant="h6">
                {t(item.title)}
              </Typography>
              <Typography sx={{ fontFamily: isRTL?'El Messiri':  'lato', fontWeight: '500' }} variant="subtitle1" gutterBottom>
                {t(item.question)}
              </Typography>
            </CardContentStyled>
            <AnswerBox className="answer">
              <Typography sx={{ fontFamily: isRTL?'El Messiri':  'lato', fontWeight: '700' ,fontSize:'20px'}} variant="body1" align="center">
                {t(item.answer)}
              </Typography>
            </AnswerBox>
          </CardWrapper>
        ))}
      </Box>
    </Container>
  );
};

export default Method;
