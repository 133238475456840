import CouchFramework from "../../COUCHPROGRAM/CouchFramework";
import Contact from "../CONTACT/Contact";
import Footer from "../FOOTER/Footer";

import Navbar from "../NAVBAR/Navbar";

function ContactPage() {
 
    return (
      <div className="App">
        <Navbar/>
      
       <Contact></Contact>
  <Footer></Footer>
      </div>
    );
  }
  
  export default ContactPage;
  