import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Card, useMediaQuery } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import one from '../ASSETS/1.png';
import two from '../ASSETS/2.png';
import three from '../ASSETS/3.png';
import four from '../ASSETS/4.png';
import five from '../ASSETS/5.png';
import six from '../ASSETS/6.png';
import seven from '../ASSETS/7.png';
import eight from '../ASSETS/8.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Raleway',
}));

const NumberIcon = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: '#2F2F2F',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '18px',
  marginBottom: '1rem',
}));

const ArrowIcon = styled(ArrowRightAlt)(({ theme }) => ({
  color: '#2F2F2F',
  position: 'absolute',
  right: '1rem',
  bottom: '-1rem',
}));

const cardsData = [
  { details: 'Our Approach is Simplified Examination to Maximize Profit.', title: 'Simplified Examination', icon: one, mr:{sm: '-2rem',md: '-1rem',xs: '-1rem'}, ml: {sm: '-1rem',md:'-1rem',xs: '-1rem' }},
  { details: 'Our Approach is Driven by Understanding First, Focused on Sustainable Awareness & Growth.', title: 'Driven by Understanding', icon: two, mr:{sm: '5rem',md: '3rem',xs: '5rem'}, ml:{sm: '5rem',md: '2rem',xs: '4rem' }},

  { details: 'Nurturing the Mind of the Business Owners.', title: 'Nurturing Business Owners', icon: three, mr:{sm: '-1rem',md: '-5rem',xs: '-4rem'}, ml:{sm: '-8rem',md: '-5rem',xs: '-4rem' }},
  
  { details: 'You get to Expand your Awareness on the Core Functions within your Business.', title: 'Expanding Awareness', icon: four, mr: {sm: '2rem',md:'3rem',xs: '4rem'}, ml:{sm: '2rem',md: '2rem',xs: '4rem' }},
  
  { details: 'Anchor the Critical Elements (Pressing Matters).', title: 'Anchoring Critical Elements', icon: five, mr:{sm: '1rem',md: '-4rem',xs: '-4rem'}, ml:{sm: '-7rem',md: '-5rem',xs: '-4rem' }},
  
  { details: 'Learn to Develop them, Monitor and Grow your Business.', title: 'Development and Monitoring', icon: six, mr:{sm: '2rem',md: '-3rem',xs: '-1rem'}, ml: {sm: '-3rem',md:'-3rem',xs: '-1rem'} },
  
  { details: 'One of our Key Principles | `Listen First & Talk Later.`', title: 'Key Principle', icon: seven, mr:{sm: '1rem',md: '-3rem',xs: '-2rem'}, ml:{sm: '-4rem',md: '-4rem',xs: '-2rem' }},
 
  { details: 'We Believe that | `Great Minds Look for Understanding Before Offering Wisdom.`', title: 'Belief in Understanding', icon: eight, mr: {xs:'6rem',md:'3rem',sm:'3rem'}, ml:{sm: '1rem',md:'3rem',xs: '6rem'} },
  { title: '' },
];

const Image = styled('img')({
  width: '25%',
  height: 'auto',
  borderRadius: 0,
});

export default function Consulting() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
    const items = [1, 2, 3, 4, 5, 6]; // Example items

    // Use the useMediaQuery hook to check screen size
    const isMediumScreen = useMediaQuery('(min-width: 900px) and (max-width: 1350px)');
    const handleApplyClick = () => {
        window.open('https://form.typeform.com/to/hrzWZ2Rz?typeform-source=www.impactusbh.com', '_blank', 'noopener,noreferrer');
      };
  return (
    <Box id='services' sx={{ flexGrow: 1, marginLeft: { xs: '1rem', md: '1rem' }, marginRight: { xs: '1rem', md: '1rem' } ,direction :isRTL ? 'rtl' : 'ltr'}}>
      
        <Typography sx={{fontFamily: isRTL?'El Messiri':  'Raleway', marginBottom: {xs:'3rem',md:'5rem'},marginTop:{xs:'9rem',md:'12rem'}, fontWeight: 'bold' }} variant="h4" gutterBottom align="center">
          {t("SME's Consulting")}
        </Typography>
      
      <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }}>
        {cardsData.map((item, index) => (
          <Grid item xs={12} sm={6}  md={isMediumScreen ? 6 : 4}  key={index} sx={{ position: 'relative', marginTop: '30px', }}>
            <Card sx={{ height: 250, boxShadow: index === 8 ? 'none' : '', padding: index === 8 ? '1rem' : '', backgroundColor: index === 8 ? 'transparent' : '#F2ECE1', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', marginBottom: '-2rem' }}>
              {index !== 8 && <NumberIcon sx={{ marginTop: '-2rem' }}>{index + 1}</NumberIcon>}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                <Box sx={{ marginRight: '-6rem', backgroundColor: '', marginLeft: '-5rem' }}>
                  <Image sx={{ marginRight: item.mr, marginLeft: item.ml }} src={item.icon}></Image>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      marginTop: 1,
                      color: '#2F2F2F',
                      fontFamily: isRTL?'El Messiri':  'lato',
                      fontWeight: 'bold',
                      fontSize: { xs: '17px', md: '20px' },
                      textAlign:isRTL?'right': 'left',
                    marginLeft : isRTL?'6rem':'0rem'
                    }}
                  >
                    {t(item.title)}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: 0.5,
                      color: '#2F2F2F',
                      fontFamily: isRTL?'El Messiri':  'lato',
                      fontSize: { xs: '15px', md: '16px' },
                     textAlign:isRTL?'right': 'left',
                    marginLeft : isRTL?'6rem':'0rem',
                      marginRight: isRTL?'0rem':'2rem',
                    }}
                  >
                    {t(item.details)}
                  </Typography>
                </Box>
              </Box>
              {index === 8 ? (
                <Button   onClick={handleApplyClick}
                  sx={{
                    fontFamily: isRTL?'El Messiri':  'lato',
                    fontSize: { xs: '10px', md: '15px' },
                    backgroundColor: '#2F2F2F',
                    borderRadius: '50px',
                    color: 'white',
                    padding: { xs: '1rem', md: '1.5rem' },
                    fontWeight: 700,
                    border: '1px solid #2F2F2F',
                    marginTop: { xs: '-10rem', md: '0.5rem' },
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#2F2F2F',
                      border: '1px solid #2F2F2F',
                    },
                  }}
                >
                  {t("Book Business Consultancy Session")}
                  <ArrowRightAlt sx={{ marginLeft: { xs: '0rem', md: '0rem' }, fontSize: '30px', rotate :isRTL ? '180deg' : '0'}} />
                </Button>
              ) : (
                <ArrowIcon sx={{marginRight:'1rem',fontSize:'40px',rotate :isRTL ? '180deg' : '0'}} />
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
