import React, { useState, useEffect } from 'react';
import './Navbar.css'
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../ASSETS/logoi.png';
import { HashLink as Link } from 'react-router-hash-link';
import { Scroll } from '../Scroll';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;
const navItems = [
    { label: 'About us', path: '/' },
    { label: 'Consulting', path: '/consulting' },
    { label: 'Coaching', path: '/couching' },
    { label: 'Mentoring', path: '/mentoring' },
    { label: 'Contact', path: '/contact' }
];

function Navbar(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const { window: windowProp } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img src={logo} style={{ width: '100px', height: '110px' }} alt="Logo" />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to={item.path} scroll={el => Scroll(el)} smooth>
              <ListItemText primary={t(item.label)} />
            </ListItemButton>
          </ListItem>
        ))}
        
        <ListItem className='css-10hburv-MuiTypography-root'
          button
          sx={{
            justifyContent: 'center',
            fontFamily: isRTL ? 'El Messiri' : "Lato",
            color: '#006747',
            '& .MuiTypography-root': {
              fontFamily: isRTL ? 'El Messiri' : "Lato",},
            '& .MuiListItemText-root': {
              textAlign: 'center',
              '&:hover': {
                backgroundColor: '#80B3A3',
                border: '1px solid #80B3A3',
                color: 'white'
              }
            },
          }}
        >
          <Button
            size="large"
            onClick={() => handleLanguageChange(isRTL ? 'en' : 'ar')}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: '#2f2f2f',
              ml: 6,
              fontFamily: 'lato',
              border: '1px solid #FFCB74',
              borderRadius: '10px',
              marginLeft: '4.5rem',
              backgroundColor: '#FFCB74',
             
            }}
          >
            {isRTL ? 'EN' : 'AR'}
          </Button>
          <ListItemText primary="" />
        </ListItem>
      </List>
    </Box>
  );

  const container = windowProp !== undefined ? () => windowProp().document.body : undefined;

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          sx={{
            backgroundColor: '#F8FBFA',
            marginTop: '0rem',
          }}
          component="nav"
          elevation={0}
        >
          <Toolbar>
            <IconButton
              color='#2f2f2f'
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon sx={{ color: '#2f2f2f' }} />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, marginLeft: { xs: '1rem', md: '5rem' }, marginTop: '0.5rem', marginRight: isRTL ? '4rem' : '0rem' }}
            >
              <img src={logo} style={{ maxWidth: scrolled ? '80px' : '120px', maxHeight: scrolled ? '75px' : '130px', marginTop: scrolled ? '0.2rem' : '0rem', }} alt="Logo" />
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' }, marginRight: { sm: '2rem', md: '2rem' }, }}>
              {navItems.map((item) => (
                <Button
                  key={item.label}
                  component={Link}
                  to={item.path}
                  scroll={(el) => Scroll(el)}
                  smooth
                  sx={{
                    fontFamily: isRTL ? 'El Messiri' : 'lato',
                    fontSize: isRTL ? '17px' : '13px',
                    color: '#3E1703',
                    marginLeft: { xs: '0rem', md: '2rem' },
                    fontWeight: 'bold',
                    border: item.label === 'Contact' ? '3px solid #FFCB74' : 'none',
                    color: item.label === 'Contact' ? '#FFCB74' : '#3E1703',
                    '&:hover': {
                      backgroundColor: '#FFCB74',
                      color: '#3E1703',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#FFCB74',
                      color: '#3E1703',
                    }
                  }}
                >
                  {t(item.label)}
                </Button>
              ))}
              {/* Language button on larger screens */}
              <Button
                size="large"
                onClick={() => handleLanguageChange(isRTL ? 'en' : 'ar')}
                sx={{
                  display: { xs: 'none', sm: 'inline-flex' },
                  alignItems: 'center',
                  color: '#2f2f2f',
                  ml: 6,
                  fontFamily: isRTL ? 'Rubik' : 'lato',
                  fontWeight: '900',
                  border: '1px solid #FFCB74',
                  borderRadius: '10px',
                  marginLeft: '2rem',
                  marginRight: '0rem',
                  backgroundColor: '#FFCB74',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    border: '1px solid #FFCB74',
                    color: '#2f2f2f',
                    fontWeight: 'bold',
                  }
                }}
              >
                {isRTL ? 'EN' : 'AR'}
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </div>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
