import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Card, Avatar, useMediaQuery } from '@mui/material';
import one from '../ASSETS/a.png'
import two from '../ASSETS/b.png'
import three from '../ASSETS/c.png'
import four from '../ASSETS/d.png'
import five from '../ASSETS/e.png'
import six from '../ASSETS/f.png'
import seven from '../ASSETS/g.png'
import eight from '../ASSETS/i.png'
import nine from '../ASSETS/j.png'
import { useTranslation } from 'react-i18next';
import { MonetizationOn, TipsAndUpdates, BusinessCenter, TrendingUp, Link, Monitor, Hearing, Psychology, ArrowRightAlt, CoPresent, Expand, SafetyDivider, WifiFind, PersonSearch, Draw, Support, Sports } from '@mui/icons-material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Raleway'
}));

const cardsData = [
  { details: 'Coaching brings to you Mindfulness, Awareness & Presence.', title: 'Mindfulness, Awareness & Presence', icon: one, m: '1rem',mt:'1rem' },
  { details: 'Visualize the Factors which are Cultivating Your Growth, and also which are Potentially Holding you Back.', title: 'Visualizing Growth Factors', icon: two, m: '1rem', bg: '#006747ff' ,mt:'1rem'},
  { details: 'Awareness is the Greatest Change Agent! Your Perspective determines how you Interpret things, Feel, Behave, and Make Decisions.', title: 'Awareness as a Change Agent', icon: three, m: '1rem', bg: '#006747ff',mt:'0.5rem' },
  { details: 'Find a Safe Space & a Thought Provoking one.', title: 'Safe and Thought-Provoking Space', icon: four, m: '1rem', bg: '#006747ff',mt:'2rem' },
  { details: 'Enable You to Discover the WHY (Purpose).', title: 'Discovering Purpose', icon: five, m: '1rem', bg: '#006747ff',mt:'2rem' },
  { details: 'The Possibilities, Capabilities and Potential Within.', title: 'Exploring Possibilities', icon: six, m: '1rem', bg: '#006747ff',mt:'2rem' },
  { details: 'The Challenges to Consider.', title: 'Identifying Challenges', icon: seven, m: '1rem', bg: '#006747ff',mt:'2rem' },
  { details: 'Design Actions.', title: 'Designing Actions', icon: eight, m: '1rem', bg: '#006747ff',mt:'2rem' },
  { title: 'Building a Support System', icon:nine, details: 'Build a Support System.',mt:'2rem' },
];
const Image = styled('img')({
    width: '15%',
    height: 'auto',
    borderRadius: 0,
   
  });
  const ArrowIcon = styled(ArrowRightAlt)(({ theme }) => ({
    color: '#2F2F2F',
    position: 'absolute',
    right: '1rem',
    bottom: '1rem',
  }));
export default function Couching() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
    const items = [1, 2, 3, 4, 5, 6]; // Example items

    // Use the useMediaQuery hook to check screen size
    const isMediumScreen = useMediaQuery('(min-width: 900px) and (max-width: 1350px)');
    const handleApplyClick = () => {
        window.open('https://form.typeform.com/to/SO0x2ZlX?typeform-source=www.impactusbh.com', '_blank', 'noopener,noreferrer');
      };
  return (
    <Box id='services' sx={{ flexGrow: 1, marginLeft: { xs: '1rem', md: '1rem' }, marginRight: '1rem',direction :isRTL ? 'rtl' : 'ltr' }}>
      
        <Typography sx={{fontFamily: isRTL?'El Messiri':  'Raleway', marginBottom: '5rem', fontWeight: 'bold',marginTop:{xs:'0rem',md:'14rem'} }} variant="h4" gutterBottom align="center">{t("Coaching")}</Typography>
   
    
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }}>
        {cardsData.map((item, index) => (
          <Grid item xs={12} sm={6} md={isMediumScreen ? 6 : 4}  key={index}>
            <Card sx={{ height: 270, backgroundColor: '#F6F6F6', position: 'relative',borderRadius:'15px' }}>
              <Avatar
                sx={{
                  bgcolor: '#2F2F2F',
                  color: 'white',
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  width: 30,
                  height: 30,
                  fontSize: '0.875rem'
                }}
              >
                {index + 1}
              </Avatar>
              <Grid container spacing={2} sx={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: '1rem' }}>
                <>
                  <Grid item xs={12}>
                  <Box sx={{ marginRight: '-6rem',backgroundColor:'',marginLeft:'-5rem' }}>
                 <Image sx={{marginTop:item.mt}} src={item.icon}></Image> 
                </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        marginTop: 1,
                        color: '#2F2F2F',
                        fontFamily: isRTL?'El Messiri':  'lato',
                        fontWeight: 'bold',
                        fontSize: '1.25rem',
                      }}
                    >
                      {t(item.title)}
                    </Typography>
                    <Typography
                      sx={{
                        marginTop: 0.5,
                        color: '#2F2F2F',
                        fontFamily: 'lato',
                        marginBottom: { sm: '3rem', xs: '2.5rem', md: '0rem' },
                      }}
                    >
                      {t(item.details)}
                    </Typography>
                    <ArrowIcon sx={{marginRight:'1rem',fontSize:'40px',rotate :isRTL ? '180deg' : '0'}} />

                  </Grid>
                </>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button  onClick={handleApplyClick}
        sx={{
          fontFamily: isRTL?'El Messiri':  'lato',
          fontSize: { xs: '12px', md: '15px' },
          backgroundColor: '#2F2F2F',
          borderRadius: '50px',
          color: 'white',
          padding: { xs: '0.9rem', md: '1.5rem' },
          fontWeight: 700,
          border: '1px solid #2F2F2F',
          marginTop: '2rem',
          '&:hover': {
            backgroundColor: 'white',
            color: '#2F2F2F',
            border: '1px solid #2F2F2F',
           
          },
        }}
      >
        {t("Book Complimentary Coaching Session")}
        <ArrowRightAlt sx={{ marginLeft: '0rem', fontSize: '30px' ,rotate :isRTL ? '180deg' : '0'}}></ArrowRightAlt>
      </Button>
    </Box>
  );
}
