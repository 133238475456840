import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

const steps = [
  {
    label: 'Entering',
    description: `Understanding Challenges - Differentiations Analysis of Symptoms & Real`,
  },
  {
    label: 'Contracting',
    description: 'On Specific Goals, Roles, Accountabilities, Rules, Duration Resources',
  },
  {
    label: 'Joint Diagnosis',
    description: `On Org Performance, SWOT Analysis, Internal Competencies, Control & Decision Making, Culture.`,
  },
  {
    label: 'Intervening',
    description: `Presentations are communication tools that can be used as demonstrations, lectures, speeches, reports, and more.`,
  },
  {
    label: 'Sustaining Change',
    description: `Monitoring & Measuring Success, Engraving Methods & Processes into Org DNA`,
  },
];

export default function Engagement() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box sx={{ backgroundColor: '', margin: { xs: '1rem 4rem', md: '2rem 12rem' } }}>
            <Typography
              sx={{
                fontFamily: isRTL?'El Messiri':  'Raleway',
                marginTop: '0rem',
                marginBottom: '5rem',
                fontWeight: '800',
                color: '#2F2F2F',
                fontSize: { xs: '35px', md: '50px' },
                direction:isRTL?'rtl':'ltr',
                textAlign:isRTL?'center':'left',
              }}
              variant="h3"
              gutterBottom
            >
              {t("ENGAGEMENT STAGES CONSULTATION JOURNEY")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stepper  sx={{ fontFamily: isRTL?'El Messiri':  'lato', marginLeft: '6rem', }} activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  sx={{
                    fontFamily: isRTL?'El Messiri':  'lato',
                    fontSize: '20px',
                    '& .MuiStepLabel-label': {
                      fontFamily: 'Raleway',
                      fontSize: '20px',
                      fontWeight: '700',
                      color: 'grey',
                    },
                    '& .MuiStepLabel-label.Mui-active': {
                      fontFamily: isRTL?'El Messiri':  'lato',
                      color: '#2F2F2F',
                    },
                    '& .MuiStepLabel-label.Mui-completed:after': {
                      fontFamily: isRTL?'El Messiri':  'lato', color:'#ffcb74'
                    },
                    '& .MuiStepIcon-root.Mui-active': { color: '#FFCB74' },
                    '& .MuiStepIcon-root.Mui-completed': { color: '#FFCB74' },
                    '& MuiStepLabel-iconContainer.Mui-completed': { color: '#FFCB74' },
                    '& MuiStepLabel-iconContainer.Mui-active': { color: '#FFCB74' },
                    '& .MuiStepIcon-completed': { color: '#FFCB74' },
                  }}
                  optional={
                    index === 4 ? (
                      <Typography sx={{fontFamily: isRTL?'El Messiri':  'lato', }} variant="caption">
                        {t("Last stage")}
                      </Typography>
                    ) : null
                  }
                >
                  {t(step.label)}
                </StepLabel>
                <StepContent>
                  <Typography sx={{ fontFamily: isRTL?'El Messiri':  'lato', marginRight: '8rem' }}>{t(step.description)}</Typography>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{
                          mt: 1,
                          mr: 1,
                          fontFamily: isRTL ? 'El Messiri' : 'lato',
                          backgroundColor: 'teal',
                          '&:hover': { backgroundColor: 'teal' },
                        }}
                      >
                         {t(index === steps.length - 1 ? 'Finish' : 'Continue')}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{
                          mt: 1,
                          mr: 1,
                          fontFamily: isRTL?'El Messiri':  'lato',
                          color: '#939393',
                          '&:hover': { backgroundColor: 'transparent', color: '#939393' },
                        }}
                      >
                        {t("Back")}
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3, backgroundColor: 'transparent' }}>
              <Typography sx={{fontFamily: isRTL?'El Messiri':  'lato', fontWeight: '500', marginLeft: '6rem' }}>
                {t("All stages completed - you're finished")}
              </Typography>
              <Button
                onClick={handleReset}
                sx={{
                  mt: 1,
                  mr: 1,
                  fontFamily: isRTL?'El Messiri':  'lato',
                  color: 'white',
                  backgroundColor: 'teal',
                  '&:hover': { backgroundColor: 'teal' },
                  marginLeft: '6rem',
                }}
              >
                {t("Reset")}
              </Button>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
