import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery,useTheme } from '@mui/material';
import { Assessment, Handshake, ImportContacts, Language, School, SchoolOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
const style = {
  p: 0,
  width: '100%',
  maxWidth: 850,
  borderRadius: 2,
  border: '1px solid',
  borderColor: 'divider',
  background: 'linear-gradient(135deg, #f5f7fa, #D3CEC8)',
  margin: 'auto', // Center the list horizontally
};

export default function MentoringDef() {
    const { t, i18n } = useTranslation();
    const theme =useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isRTL = i18n.language === 'ar';
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        margin:{xs:'1rem',md: 'auto'},
        paddingTop: { xs: '6rem', md: '12rem' },
        paddingBottom: '4rem',
        direction:isRTL?'rtl':'ltr'
        
      }}
    >
      <Typography
        sx={{
            fontFamily: isRTL?'El Messiri':  'Raleway',
          fontWeight: 'bold',
          color: '#2f2f2f',
          marginBottom: '4rem',
          marginTop:{xs:'1rem',md:'3rem'},
          
        }}
        variant="h4"
      >
        {t("Effective and Relevant Mentoring")}
      </Typography>
      <List sx={style} aria-label="mailbox folders">
        <ListItem>
        <ListItemIcon>
            <ImportContacts sx={{color:'#FFCB74',marginLeft:'0.5rem',fontSize:'40px'}} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography sx={{ fontFamily: isRTL?'El Messiri':  'lato',fontSize:'20px',margin:'1rem',fontWeight:'500',marginLeft:{xs:isRTL?'2rem':'0rem',md:isRTL?'15rem':'0rem'},textAlign:{xs:isRTL?'center':'left',md:isRTL?'center':'left'}}}>
                {t("Addresses knowledge and experience gaps through a tailored approach.")}
              </Typography>
            }
          />
        </ListItem>
        <Divider component="li" />
        <ListItem>
        <ListItemIcon>
            <Language sx={{color:'#FFCB74',marginLeft:'0.5rem',fontSize:'40px'}}  />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography sx={{ fontFamily: isRTL?'El Messiri':  'lato',fontSize:'20px',margin:'1rem',marginLeft:{xs:isRTL?'2rem':'0rem',md:isRTL?'15rem':'0rem'},textAlign:{xs:isRTL?'center':'left',md:isRTL?'center':'left'} }}>
                {t("Customizes mentoring based on business dynamics, language, and destination.")}
              </Typography>
            }
          />
        </ListItem>
        <Divider component="li" />
        <ListItem>
        <ListItemIcon>
            <Handshake sx={{color:'#FFCB74',marginLeft:'0.5rem',fontSize:'40px'}}  />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography sx={{fontFamily: isRTL?'El Messiri':  'lato',fontSize:'20px',margin:'1rem',marginLeft:{xs:isRTL?'2rem':'0rem',md:isRTL?'15rem':'0rem'},textAlign:{xs:isRTL?'center':'left',md:isRTL?'center':'left'}}}>
                {t("Enhances compatibility and matching to maximize mentoring benefits.")}
              </Typography>
            }
          />
        </ListItem>
        <Divider component="li" />
        <ListItem>
        <ListItemIcon>
            <Assessment sx={{color:'#FFCB74',marginLeft:'0.5rem',fontSize:'40px'}}  />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography sx={{ fontFamily: isRTL?'El Messiri':  'lato',fontSize:'20px',margin:'1rem',marginLeft:{xs:isRTL?'2rem':'0rem',md:isRTL?'15rem':'0rem'},textAlign:{xs:isRTL?'center':'left',md:isRTL?'center':'left'} }}>
                {t("Increases the probability of a productive and effective mentoring experience.")}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
}
