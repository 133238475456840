import CouchFramework from "../../COUCHPROGRAM/CouchFramework";
import CouchProgram from "../../COUCHPROGRAM/CouchProgram";
import Footer from "../FOOTER/Footer";

import Navbar from "../NAVBAR/Navbar";

function CouchingPage() {
 
    return (
      <div className="App">
        <Navbar/>
       <CouchFramework/>
       <CouchProgram></CouchProgram>
  <Footer></Footer>
      </div>
    );
  }
  
  export default CouchingPage;
  