import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Avatar, Card, CardActions, CardContent, CardHeader, Collapse, IconButton, Typography } from '@mui/material';
import { Favorite, ShareRounded, ExpandMore as ExpandMoreIcon, Business, BusinessCenter, Mood, Groups, Leaderboard } from '@mui/icons-material';
import { red} from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie } from '@fortawesome/fontawesome-free-solid'
import { useTranslation } from 'react-i18next';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CouchProgram() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [expandedIndex, setExpandedIndex] = React.useState(null);

  const handleExpandClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Box sx={{ flexGrow: 1, marginTop: '6rem',marginBottom:'4rem',marginLeft:{xs:'1rem',md:'0rem'} ,marginRight:{xs:'1rem',md:'0rem'},direction:isRTL?'rtl':'ltr'}}>
      <Typography sx={{ fontFamily: isRTL?'El Messiri':  'Raleway', marginTop: '7rem', fontWeight: 'bold', marginBottom: '4rem' }} variant="h4" gutterBottom align="center">
        {t("Coaching Programs")}
      </Typography>

      <Grid container sx={{ flexWrap: 'wrap', display: 'flex', gap: 2 }} justifyContent="center">
        {[
          {
            title: 'Executive Coaching',
            avatar: <FontAwesomeIcon icon={faUserTie} />,
            description: 'For C-Suite & VPs to transition from `domain expert` to `transformational executive` with presence and enterprise-wide impact.',
            method: 'Aims to help experienced leaders become effective change agents, driving improvements for their companies, teams, and themselves by enhancing strategic vision, stakeholder influence, business outcomes, team inspiration, and self-awareness to manage conflict.',
          },
          {
            title: 'Leadership Coaching',
            avatar: <Leaderboard/>,
            description: 'For Directors and Senior Managers to achieve better strategic, business, and team results while preparing for future executive roles.',
            method: 'Helps Directors and Senior Managers develop deeper stakeholder awareness, self-insight, communication mastery, and optimal planning and interaction styles to exceed business expectations and prepare for higher roles.',
          },
          {
            title: 'Team Coaching',
            avatar: <Groups/>,
            description: 'For Leaders and their teams to bring clarity, awareness, and coherence towards their performance, behaviors, and goals.',
            method: 'Facilitates team understanding of current realities, assesses personalities and capabilities, evaluates strengths and weaknesses, facilitates consensus in meetings, and drives tangible plans and results.',
          },
          {
            title: 'Business Coaching',
            avatar:<BusinessCenter/>,
            description: 'To enhance business operations, acknowledge progress, support data-driven decision making, and safeguard against recession.',
            method: 'Enhances perception and understanding of business operations, acknowledges progress, supports data-driven decision making, and safeguards against recession.',
          },
          {
            title: 'Emotional Intelligence Coaching',
            avatar: <Mood/>,
            description: 'Emotional Intelligence Coaching boosts self-awareness, emotional control, and resilience, enhancing decision-making and relationships.',
            method: 'Focuses on self-awareness, understanding and controlling emotional behaviors, maintaining and developing interpersonal relationships, improving decision making and problem solving, and building resilience against challenges and stress. Supports business owners in understanding their business, pinpointing critical areas, and safeguarding against recession.',
          },
        ].map((program, index) => (
          <Grid item xs={12} sm={6} md={8} key={index} sx={{ display: 'flex' }}>
            <Card sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' ,background:'linear-gradient(135deg, #f5f7fa, #D3CEC8)'

            }}>
              <CardHeader 
                avatar={<Avatar sx={{ background:'#E9C182' }} aria-label="recipe">{program.avatar}</Avatar>}
                title={<Typography sx={{fontSize:'20px',fontFamily: isRTL?'El Messiri':  'lato',fontWeight:'600',marginRight:isRTL?'1rem':'0rem'}}>{t(program.title)}</Typography>}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography  sx={{fontSize:'18px',fontFamily: isRTL?'El Messiri':  'lato',fontWeight:'500',color:'#2f2f2f',marginRight:isRTL?'0rem':'0rem'}} variant="body2" color="text.secondary">
                  {t(program.description)}
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                  {/* <Favorite /> */}
                </IconButton>
                <IconButton aria-label="share">
                 {/*  <ShareRounded /> */}
                </IconButton>
                <ExpandMore
                  expand={expandedIndex === index}
                  onClick={() => handleExpandClick(index)}
                  aria-expanded={expandedIndex === index}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography sx={{fontSize:'18px',fontFamily: isRTL?'El Messiri':  'lato',fontWeight:'700',color:'grey'}} paragraph>
                    {t("Explanation")}:</Typography>
                  <Typography sx={{fontSize:'17px',fontFamily: isRTL?'El Messiri':  'lato',fontWeight:'700',color:'grey'}} paragraph>{t(program.method)}</Typography>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
