import React from 'react';
import { Card, CardContent, Typography, Box, Avatar, Grid, Paper, Container, Divider } from '@mui/material';
import { Whatshot, Build, Favorite, ThumbUp, People, TrendingUp, Assistant } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const cardData = [
  {
    title: "What We Do?",
    content: "Assisting our clients in understanding the 'Now' and effectively navigating towards their aspirations, we provide strategic guidance and actionable insights to help achieve their goals.",
    icon: <Assistant />,
    mi:'-2rem',
    bg: '#FFCB74',
    font : '#2F2F2F', 
     bgi:'#2F2F2F',
      color:'white'
     // Orange
  },
  {
    title: "How We Do It?",
    content: "Through our professional and certified coaching, mentoring, and comprehensive management consultancy, we provide expert guidance and support tailored to meet your specific needs.",
    icon: <Build />,
    mi:'-2rem',
    bg: '#F6F6F6', 
    font : '#2F2F2F', 
    bgi:'#2F2F2F',
    color:'white'// Green
  },
  {
    title: "Why We Do It?",
    content: "Our purpose is to do meaningful work by continuously serving our community, which helps us grow both humanely and organically. This way, we perform at our best and can give abundantly",
    icon: <Favorite />,
    mi:'-2rem',
    bg: '#2F2F2F',
    font : 'white',
    bgi:'white',
    color:'#2F2F2F' // Blue
  }
];

const About = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <>
      <Typography sx={{fontFamily: isRTL?'El Messiri':  'Raleway',marginTop:'7rem',fontWeight:'bold',marginBottom:'4rem'}} variant="h4" gutterBottom align="center">
       {t("About Us")}
      </Typography>
   
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
        mt: 10,
        margin:{xs:'1rem',md:'0rem'},
        direction:isRTL ? 'rtl' : 'ltr'
      }}
    >
      
      {cardData.map((item, index) => (
        <Card key={index} sx={{ maxWidth: 400, width: '100%', boxShadow: 3,backgroundColor:item.bg,}}>
          <CardContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
             
              color: item.font,
            
            }}
          >
            <Avatar sx={{ bgcolor: item.bgi
                , color: item.color ,marginTop:item.mi}}>{item.icon}</Avatar>
            <Box>
              <Typography sx={{fontWeight:'700',fontFamily: isRTL?'El Messiri':  'lato',marginTop:'1rem',}} variant="h6" gutterBottom>
                {t(item.title)}
              </Typography>
              <Typography sx={{fontFamily: isRTL?'El Messiri':  'lato',marginBottom:'1rem',fontWeight:'500'}} variant="body1">
                {t(item.content)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
   
    </Box>
    </>
  );
};

export default About;
