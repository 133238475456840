import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const embedMapStyles = {
  maxWidth: '100%',
  overflow: 'hidden',
  width: '100%',
  height: '500px', // Adjusted to make it responsive
  border: '2px solid #2f2f2f',
  
};

export default function Contact() {
    const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <Box
      id="contact"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '0rem',
        margin: { xs: '5rem 1rem', md: '12rem 5rem' },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: '25px', md: '35px' },
          fontWeight: 'bold',
          lineHeight: '1',
          marginBottom: '4rem',
          fontFamily: isRTL?'El Messiri': 'ٌRaleway',
          color: '#2f2f2f',
        }}
      >
        {t("Location")}
      </Typography>
      <Box sx={{ flexGrow: 1, marginTop: '2rem', direction: 'rtl', width: '100%' }}>
        <div style={{ ...embedMapStyles,  }}>
          <div id="embed-map-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}>
            <iframe
              style={{ height: '100%', width: '100%', border: '0' }}
              frameBorder="0"
              src="https://maps.google.com/maps?q=Office%20Number%202543,%20Bldg.%20No.%201565,%20Road%201722,%20Block%20317,%20Diplomatic%20Area,%20Manama,%20Kingdom%20of%20Bahrain&t=m&z=13&ie=UTF8&output=embed"
              allowFullScreen
              title="Embedded Google Map"
            ></iframe>
          </div>
        </div>
      </Box>
    </Box>
  );
}
